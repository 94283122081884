<script>
export default {
  data() {
    return {
      footerYear: new Date().getFullYear(),
      appVersion: process.env.VUE_APP_APP_VERSION
    };
  },
  methods: {
    decryptMailtoEmail(encoded) {
      var address = atob(encoded);
      window.location.href = "mailto:" + address;
    }
  }
}
</script>
<template>
  <footer class="footer">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-6 text-lg-left">
          © {{ footerYear }} <img src="@/assets/images/si-pay-logo.png" alt height="15" class="mt-n1" /> SI-PAY <span class="text-muted small">{{ appVersion }}</span>
        </div>
        <div class="col-lg-6 text-lg-right">
          <!-- <a href="#" @click="decryptMailtoEmail('aXBwQGltcGVsLnBs')">Pomoc</a> -->
        </div>
      </div>
    </div>
  </footer>
</template>