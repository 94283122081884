<script>
import { layoutMethods, authComputed } from "@/state/helpers";
import { roleService } from "@/app/service/roleService";

/**
 * Horizontal navbar component
 */
export default {
  data() {
    return {
      loggedUser: null,
    };
  },
  created() {
    this.loggedUser = this.currentUser();
  },
  mounted() {
    var links = document.getElementsByClassName("side-nav-link");
    var matchingMenuItem = null;
    for (var i = 0; i < links.length; i++) {
      if (window.location.pathname === links[i].pathname) {
        matchingMenuItem = links[i];
        break;
      }
    }

    if (matchingMenuItem) {
      matchingMenuItem.classList.add("active");
      var parent = matchingMenuItem.parentElement;

      /**
       * TODO: This is hard coded way of expading/activating parent menu dropdown and working till level 3.
       * We should come up with non hard coded approach
       */
      if (parent) {
        parent.classList.add("active");
        const parent2 = parent.parentElement;
        if (parent2) {
          parent2.classList.add("active");
        }
        const parent3 = parent2.parentElement;
        if (parent3) {
          parent3.classList.add("active");
          var childAnchor = parent3.querySelector(".has-dropdown");
          if (childAnchor) childAnchor.classList.add("active");
        }

        const parent4 = parent3.parentElement;
        if (parent4) parent4.classList.add("active");
        const parent5 = parent4.parentElement;
        if (parent5) parent5.classList.add("active");
      }
    }
  },
  methods: {
    ...layoutMethods,
    ...authComputed,
    /**
     * menu clicked show the subelement
     */
    onMenuClick(event) {
      event.preventDefault();
      const nextEl = event.target.nextSibling;
      if (nextEl && !nextEl.classList.contains("show")) {
        const parentEl = event.target.parentNode;
        if (parentEl) {
          parentEl.classList.remove("show");
        }
        nextEl.classList.add("show");
      } else if (nextEl) {
        nextEl.classList.remove("show");
      }
      return false;
    },
    topbarLight() {
      document.body.setAttribute("data-topbar", "light");
      document.body.removeAttribute("data-layout-size", "boxed");
    },
    boxedWidth() {
      document.body.setAttribute("data-layout-size", "boxed");
      document.body.removeAttribute("data-topbar", "light");
      document.body.setAttribute("data-topbar", "dark");
    },
    changeLayout(layout) {
      this.changeLayoutType({ layoutType: layout });
    },
  },
  computed: {
    isAdmin() {
      return roleService.isAdmin();
    },
    isProductAdministrator() {
      return roleService.isProductAdministrator();
    },
    isSuperAccepting() {
      return roleService.isSuperAccepting();
    },
    isFinancialOperator() {
      return roleService.isFinancialOperator();
    },
  },
};
</script>
<template>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">

            <li class="nav-item" v-if="!isAdmin && !isSuperAccepting && !isFinancialOperator && !isProductAdministrator"><span class="nav-link side-nav-link">Strona główna</span></li>

            <li class="nav-item" v-if="isAdmin || isSuperAccepting || isFinancialOperator">
              <router-link tag="a" class="nav-link side-nav-link" to="/">
                <i class="ti-receipt mr-2"></i>Wnioski
              </router-link>
            </li>

            <li class="nav-item dropdown" v-if="isAdmin">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="onMenuClick">
                <i class="fas fa-users mr-2"></i>Użytkownicy
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-layout">
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Dodaj użytkownika'}" class="dropdown-item side-nav-link"><i class="fas fa-user-plus mr-2"></i>Dodaj użytkownika</router-link>
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Lista użytkowników'}" class="dropdown-item side-nav-link"><i class="fas fa-user-friends mr-2"></i>Lista użytkowników</router-link>
              </div>
            </li>

            <li class="nav-item" v-if="isAdmin">
              <router-link tag="a" class="nav-link side-nav-link" to="/company/list">
                <i class="mdi mdi-office-building mr-2"></i>Firmy
              </router-link>
            </li>

            <li class="nav-item" v-if="isAdmin || isProductAdministrator">
              <router-link tag="a" class="nav-link side-nav-link" to="/bank/list">
                <i class="mdi mdi-bank-outline mr-2"></i>Banki
              </router-link>
            </li>

            <li class="nav-item" v-if="isAdmin || isProductAdministrator || isFinancialOperator">
              <router-link tag="a" class="nav-link side-nav-link" to="/bank-accounts/list">
                <i class="dripicons-wallet mr-2"></i>Rachunki bankowe
              </router-link>
            </li>

            <li class="nav-item" v-if="isAdmin || isProductAdministrator">
              <router-link tag="a" class="nav-link side-nav-link" to="/prepaid-cards/list">
                <i class="dripicons-card mr-2"></i>Karty
              </router-link>
            </li>

            <li class="nav-item dropdown" v-if="isAdmin">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-reports" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="onMenuClick">
                <i class="mdi mdi-google-analytics mr-2"></i>Raporty
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-reports">
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Liczba zrealizowanych przelewów'}" class="dropdown-item side-nav-link"><i class="mdi mdi-settings-transfer-outline mr-2"></i>Liczba zrealizowanych przelewów</router-link>
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Potwierdzenia przelewów'}" class="dropdown-item side-nav-link"><i class="mdi mdi-attachment mr-2"></i>Potwierdzenia przelewów</router-link>
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Historia logowania'}" class="dropdown-item side-nav-link"><i class="mdi mdi-login mr-2"></i>Historia logowania</router-link>
              </div>
            </li>

            <li class="nav-item dropdown" v-if="isAdmin">
              <a class="nav-link dropdown-toggle arrow-none" href="javascript: void(0);" id="topnav-layout" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" @click="onMenuClick">
                <i class="fas fa-cog mr-2"></i>System
              </a>
              <div class="dropdown-menu" aria-labelledby="topnav-layout">
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Lista kontaktów'}" class="dropdown-item side-nav-link"><i class="mdi mdi-email mr-2"></i>Kontakty</router-link>
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Lista szablonów plików bankowych'}" class="dropdown-item side-nav-link"><i class="mdi mdi-bank-transfer mr-2"></i>Lista szablonów plików bankowych</router-link>
                <router-link v-if="isAdmin" tag="a" :to="{ name: 'Lista odpowiedzi interfejsów'}" class="dropdown-item side-nav-link"><i class="mdi mdi-settings-transfer mr-2"></i>Odpowiedzi interfejsów</router-link>
              </div>
            </li>

          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>
