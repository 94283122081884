import store from '@/state/store'

export const roleService = {
  isApplicant,
  isAccepting,
  isSuperAccepting,
  isAcceptingKRS,
  isFinancialOperator,
  isProductAdministrator,
  isAdmin,
};

function roles() {
  return store.getters["auth/loggedIn"].roles;
}

// Wnioskujący
function isApplicant() {
  return roles().includes(
    "ROLE_APPLICANT"
  );
}

// Akceptujący przelewy
function isAccepting() {
  return roles().includes(
    "ROLE_ACCEPTING"
  );
}

// Akceptujący zasilenia kart
function isAcceptingCard() {
  return roles().includes(
    "ROLE_ACCEPTING_CARD"
  );
}

// Akceptujący wyższego rzędu
function isSuperAccepting() {
  return roles().includes(
    "ROLE_SUPER_ACCEPTING"
  );
}

// Akceptujący KRS
function isAcceptingKRS() {
  return roles().includes(
    "ROLE_ACCEPTING_KRS"
  );
}

// Dział operacji finansowych
function isFinancialOperator() {
  return roles().includes(
    "ROLE_FINANCIAL_OPERATIONS"
  );
}

// Administrator produktu finansowego
function isProductAdministrator() {
  return roles().includes(
    "ROLE_PRODUCT_ADMINISTRATOR"
  );
}

// Administrator
function isAdmin() {
  return roles().includes(
    "ROLE_ADMIN"
  );
}
